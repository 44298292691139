import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import shirtImage from '../../Media/Images/manimg.png';
import Shipping from '../../Media/Icon/shipping.png';
import lock from "../../Media/Icon/lock.png";
import returnback from "../../Media/Icon/return.png";
import bestprice from "../../Media/Icon/bestprice.png";
import Product from "./Product";

class Home extends Component {
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {
            email: '',
            subject: 'Welcome to Our Store',
            message: 'Thank you for joining our mailing list!'
        };
    }

    scrollLeft = () => {
        this.carouselRef.current.scrollBy({
            left: -300,
            behavior: 'smooth'
        });
    };

    scrollRight = () => {
        this.carouselRef.current.scrollBy({
            left: 300,
            behavior: 'smooth'
        });
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    handleSendEmail = () => {
        const { email } = this.state;

        fetch('/api/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error('Error:', error));
    };

    render() {
        return (
            <div>
                <div className="home-container">
                    <div className="text-content">
                        <h2 className="discount">Up to 50% Off</h2>
                        <h1 className="main-heading">Shirt For Men</h1>
                        <p className="description">
                            Discover the latest trends in men's fashion with our exclusive collection of shirts. Stylish and comfortable, perfect for any occasion.
                        </p>
                        <button className="shop-now">Shop Now!</button>
                    </div>
                    <div className="image-content">
                        <img src={shirtImage} alt="Shirt" className="shirt-image" />
                    </div>
                </div>

                <h2 className="product-heading">Our Products</h2>
                <div className="product-carousel-container">
                    <div className="product-carousel">
                        <Product/>
                    </div>
                </div>

                <div className='show-btn'>
                    <Link to="/products">
                        <button className="show-more-products">Show More</button>
                    </Link>
                </div>

                <h2 className="product-heading s-heading">Services</h2>
                <div className="services-section">
                    <div className="services-container">
                        <div className="service-item">
                            <img src={Shipping} alt="Free Shipping" className="service-icon" />
                            <h3 className="service-heading">Free Shipping</h3>
                            <p className="service-description">Order Over $100</p>
                        </div>
                        <div className="service-item">
                            <img src={returnback} alt="Free Return" className="service-icon" />
                            <h3 className="service-heading">Free Return</h3>
                            <p className="service-description">Within 30 days returns</p>
                        </div>
                        <div className="service-item">
                            <img src={lock} alt="Secure Payment" className="service-icon" />
                            <h3 className="service-heading">Secure Payment</h3>
                            <p className="service-description">100% secure payment</p>
                        </div>
                        <div className="service-item">
                            <img src={bestprice} alt="Best Price" className="service-icon" />
                            <h3 className="service-heading">Best Price</h3>
                            <p className="service-description">Guaranteed price</p>
                        </div>
                    </div>
                </div>

                {/* New Section for Email Input and Button */}
                <h2 className="product-heading blog-head">Join us for the next phase</h2>
                <div className="email-section">
                    <div className="email-wrapper">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="Enter your email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                        />
                        <button className="send-email" onClick={this.handleSendEmail}>
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;