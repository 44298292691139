import React, { Component } from 'react';
import mastercard from '../../Media/Images/mastercard.jpg'
import payoneer from '../../Media/Images/payoneer.png'
import visa from '../../Media/Images/visa.jpg'
import discover from '../../Media/Images/discover.jpg'

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            state: '',
            address1: '',
            address2: '',
            postalCode: '',
            company: '',
            cardNo: '',
            expiry: '',
            cvv: '',
            errorMessage: '',
            successMessage: '',
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        
        const { firstName, lastName, email, phone, country, state, address1, postalCode, cardNo, expiry, cvv } = this.state;

        if (!firstName || !lastName || !email || !phone || !country || !state || !address1 || !postalCode || !cardNo || !expiry || !cvv) {
            this.setState({ errorMessage: 'Please enter all required details.', successMessage: '' });
        } else {
            this.setState({ successMessage: 'Your details have been taken. We will update you via email.', errorMessage: '' });
        }
    }

    render() {
        const { errorMessage, successMessage } = this.state;

        return (
            <div className="checkout-container container">
                <h3><b>Make Your Checkout Here</b></h3>
                <p>Please register in order to checkout more quickly</p>
                <div className="row">
                    <div className="col-md-8 checkout-form">
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="form-row d-flex gap-5">
                                <div className="form-group col-md-4">
                                    <label htmlFor="firstName">First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="firstName" placeholder="First Name" onChange={this.handleChange} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="lastName">Last Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="lastName" placeholder="Last Name" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form-row d-flex gap-5">
                                <div className="form-group col-md-4">
                                    <label htmlFor="email">Email Address <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="email" placeholder="Email Address" onChange={this.handleChange} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="phone">Phone Number <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="phone" placeholder="Phone Number" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form-row d-flex gap-5">
                                <div className="form-group col-md-4">
                                    <label htmlFor="state">Country<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="country" placeholder="Country" onChange={this.handleChange} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="state">State / Division <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="state" placeholder="State / Division" onChange={this.handleChange} required />
                                </div>
                            </div>
                            <div className="form-row d-flex gap-5">
                                <div className="form-group col-md-4">
                                    <label htmlFor="address1">Address Line 1 <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="address1" placeholder="Address Line 1" onChange={this.handleChange} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="address2">Address Line 2</label>
                                    <input type="text" className="form-control" id="address2" placeholder="Address Line 2" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-row d-flex gap-5">
                                <div className="form-group col-md-4">
                                    <label htmlFor="postalCode">Postal Code <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="postalCode" placeholder="Postal Code" onChange={this.handleChange} required />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="company">Company</label>
                                    <input type="text" className="form-control" id="company" placeholder="Company" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="createAccount" />
                                <label className="form-check-label checkout-form-check-label" htmlFor="createAccount">Create an account?</label>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4 checkout-payment">
                        <div className="checkout-card">
                            <div className="checkout-card-body">
                                <h4 className="checkout-card-title ul-head">CART TOTALS</h4>
                                <ul className="list-group mb-3">
                                    <li className="list-group-item checkout-list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">Sub Total</h6>
                                        </div>
                                        <span className="text-muted">$330.00</span>
                                    </li>
                                    <li className="list-group-item checkout-list-group-item d-flex justify-content-between lh-condensed">
                                        <div className='d-flex'>
                                            <h6 className="my-0">(+)</h6>
                                            <small className="text-muted">Shipping</small>
                                        </div>
                                        <span className="text-muted">$10.00</span>
                                    </li>
                                    <li className="list-group-item checkout-list-group-item d-flex justify-content-between">
                                        <span>Total</span>
                                        <strong>$340.00</strong>
                                    </li>
                                </ul>
                                <h4 className="checkout-card-title ul-head">PAYMENTS</h4>
                                <div className="form-group">
                                    <div className="form-group col-md-11">
                                        <input type="text" className="form-control" id="cardNo" placeholder="Enter Card no." onChange={this.handleChange} required />
                                    </div>
                                    <div className="form-row d-flex gap-5">
                                        <div className="form-group col-md-">
                                            <input type="text" className="form-control" id="expiry" placeholder="Expiry (MM/YY)" onChange={this.handleChange} required />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <input type="text" className="form-control" id="cvv" placeholder="CVV" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="image-row d-flex justify-content-between mb-3">
                                    <img src={mastercard} alt="Card 1" className="image-card" />
                                    <img src={visa} alt="Card 4" className="image-card" />
                                </div>
                                <button className="checkout-btn-block" type='submit' onClick={this.handleFormSubmit}>PROCEED TO CHECKOUT</button>
                                {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Checkout;
