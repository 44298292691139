import React, { Component } from "react";
import { Link } from "react-router-dom";

class CartPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			coupon: "",
			discount: 0, // State for discount percentage
		};
	}

	componentDidMount() {
		// Load products from session storage
		const storedProducts = JSON.parse(sessionStorage.getItem('cart')) || [];
		// Ensure each product has an initial quantity
		const updatedProducts = storedProducts.map(product => ({
			...product,
			quantity: product.quantity || 1,
			total: product.total || (product.price * (product.quantity || 1)),
		}));
		this.setState({ products: updatedProducts });
	}

	componentDidUpdate(prevProps, prevState) {
		// Save products to session storage whenever they change
		if (prevState.products !== this.state.products) {
			sessionStorage.setItem('cart', JSON.stringify(this.state.products));
		}
	}

	handleQuantityChange = (index, change) => {
		this.setState((prevState) => {
			const products = [...prevState.products];
			const newQuantity = products[index].quantity + change;

			// Ensure the new quantity is positive
			if (newQuantity > 0) {
				products[index].quantity = newQuantity;
				products[index].total = products[index].price * newQuantity; // Update total based on new quantity
			} else {
				console.log(`Cannot decrease quantity below 1 for product at index ${index}`);
			}

			return { products };
		});
	};

	handleCouponChange = (e) => {
		this.setState({ coupon: e.target.value });
	};

	handleApplyCoupon = () => {
		// Example logic for applying a coupon
		const { coupon } = this.state;
		let discount = 0;

		// Example logic for applying a 10% discount if coupon is 'DISCOUNT10'
		if (coupon === 'DISCOUNT10') {
			discount = 0.10; // 10% discount
		}
		this.setState({ discount });
	};

	handleDelete = (index) => {
		this.setState((prevState) => {
			const products = prevState.products.filter((_, i) => i !== index);
			return { products };
		});
	};

	render() {
		const { products, coupon, discount } = this.state;

		// Calculate the subtotal
		const subtotal = products.reduce((sum, product) => sum + (product.total || 0), 0);
		const savings = subtotal * discount; // Calculate savings based on discount percentage
		const total = subtotal - savings;

		return (
			<div className="cart-container">
				<div className="cart-table">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th>PRODUCT</th>
								<th>NAME</th>
								<th>DESCRIPTION</th>
								<th>UNIT PRICE</th>
								<th>QUANTITY</th>
								<th>TOTAL</th>
								<th>ACTION</th>
							</tr>
						</thead>
						<tbody>
							{products.map((product, index) => (
								<tr key={product.id}>
									<td>
										<img
											src={product.image || 'fallback-image.png'} // Use a fallback image if product image is not available
											alt="Product"
											className="image-section"
										/>
									</td>
									<td>{product.name}</td>
									<td>{product.description || 'No description available'}</td>
									<td>${(product.price || 0).toFixed(2)}</td>
									<td>
										<div className="quantity-container">
											<button
												className="btn-decrease"
												onClick={() => this.handleQuantityChange(index, -1)}
											>
												-
											</button>
											<input
												type="number"
												value={product.quantity || 1} // Ensure default value of 1 if quantity is undefined
												min="1"
												onChange={(e) => this.handleQuantityChange(index, parseInt(e.target.value) - product.quantity)}
											/>
											<button
												className="btn-increase"
												onClick={() => this.handleQuantityChange(index, 1)}
											>
												+
											</button>
										</div>
									</td>
									<td>${(product.total || 0).toFixed(2)}</td>
									<td>
										<button
											className="btn-delete"
											onClick={() => this.handleDelete(index)}
										>
											<i className="fas fa-trash"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="summary-row">
					<div className="coupon-section">
						<input
							type="text"
							className="input-field"
							placeholder="Enter Your Coupon"
							value={coupon}
							onChange={this.handleCouponChange}
						/>
						<button className="apply-btn" onClick={this.handleApplyCoupon}>APPLY</button>
					</div>
					<div className="cart-total">
						<div className="cart-item">
							<p>Cart Subtotal:</p>
							<span>${subtotal.toFixed(2)}</span>
						</div>
						<div className="cart-item">
							<p>Shipping:</p>
							<span>Free</span>
						</div>
						<div className="cart-item">
							<p>You Save:</p>
							<span>${savings.toFixed(2)}</span>
						</div>
						<hr className="product-divider" />
						<div className="cart-item">
							<p>Total:</p>
							<span>${total.toFixed(2)}</span>
						</div>
						<div className="btn-div">
							<Link to='/checkout' className="shop-now-cart">CHECKOUT</Link>
							<Link to='/' className="shop-now-cart">CONTINUE SHOPPING</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CartPage;
