import React, { Component } from "react";
import boyimage from "../../Media/Images/manimg.png";

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishlistItems: [], // Initialize as an empty array
    };
  }

  componentDidMount() {
    const storedWishlist = sessionStorage.getItem("wishlist");
    if (storedWishlist) {
      try {
        const wishlistItems = JSON.parse(storedWishlist);
        const validWishlistItems = wishlistItems.map((item) => ({
          ...item,
          price: item.price || 0,
          total: item.total || item.price * (item.quantity || 1),
          quantity: item.quantity || 1,
        }));
        this.setState({ wishlistItems: validWishlistItems });
      } catch (error) {
        console.error("Error parsing wishlist from session storage:", error);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.wishlistItems !== this.state.wishlistItems) {
      sessionStorage.setItem("wishlist", JSON.stringify(this.state.wishlistItems));
    }
  }

  addToWishlist = (item) => {
    this.setState(
      (prevState) => {
        const existingIndex = prevState.wishlistItems.findIndex(
          (wishlistItem) => wishlistItem.id === item.id
        );

        let updatedWishlistItems = [...prevState.wishlistItems];

        if (existingIndex > -1) {
          // Update existing item
          updatedWishlistItems[existingIndex].quantity += 1;
          updatedWishlistItems[existingIndex].total =
            updatedWishlistItems[existingIndex].price *
            updatedWishlistItems[existingIndex].quantity;
        } else {
          // Add new item
          const newItem = { ...item, quantity: 1, total: item.price };
          updatedWishlistItems.push(newItem);
        }

        return { wishlistItems: updatedWishlistItems };
      }
    );
  };

  handleQuantityChange = (index, change) => {
    console.log(`handleQuantityChange called with index: ${index}, change: ${change}`);
    this.setState(
      (prevState) => {
        const wishlistItems = [...prevState.wishlistItems];
        const currentQuantity = wishlistItems[index].quantity;
        const newQuantity = currentQuantity + change;

        console.log(`Current Quantity: ${currentQuantity}, New Quantity: ${newQuantity}`);

        if (newQuantity > 0) {
          wishlistItems[index].quantity = newQuantity;
          wishlistItems[index].total = wishlistItems[index].price * newQuantity;
        } else {
          wishlistItems.splice(index, 1);
        }

        return { wishlistItems };
      }
    );
  };

  handleAddToCart = (index) => {
    const item = this.state.wishlistItems[index];
    const storedCart = JSON.parse(sessionStorage.getItem('cart')) || [];
    const existingIndex = storedCart.findIndex(cartItem => cartItem.id === item.id);
  
    if (existingIndex > -1) {
      storedCart[existingIndex].quantity += item.quantity;
      storedCart[existingIndex].total = storedCart[existingIndex].price * storedCart[existingIndex].quantity;
    } else {
      const newItem = { ...item, quantity: item.quantity, total: item.price * item.quantity };
      storedCart.push(newItem);
    }
  
    sessionStorage.setItem('cart', JSON.stringify(storedCart));
  
    console.log(`Added item ${item.id} to cart`);
  };
  

  handleDelete = (index) => {
    this.setState(
      (prevState) => {
        const wishlistItems = prevState.wishlistItems.filter(
          (_, i) => i !== index
        );
        return { wishlistItems };
      }
    );
  };

  render() {
    const { wishlistItems } = this.state;

    return (
      <div className="wishlist-page">
        <div className="wishlist-content">
          <h2 className="wishlist-header">Wishlist</h2>
          {wishlistItems.length === 0 ? (
            <div className="no-items-message">
              No items in your favorite list 🫠.....
            </div>
          ) : (
            <table className="wishlist-table">
              <thead className="wishlist-table-head">
                <tr>
                  <th>IMAGE</th>
                  <th>DESCRIPTION</th>
                  <th>PRICE</th>
                  <th>QUANTITY</th>
                  <th>TOTAL</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {wishlistItems.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <img
                        src={item.image || boyimage}
                        alt={item.description}
                        className="wishlist-product-image"
                      />
                    </td>
                    <td>{item.description}</td>
                    <td>${item.price.toFixed(2)}</td>
                    <td>
                      <div className="quantity-control">
                        <button
                          className="btn-decrement"
                          onClick={() => this.handleQuantityChange(index, -1)}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        <input
                          type="number"
                          value={item.quantity}
                          min="1"
                          readOnly
                        />
                        <button
                          className="btn-increment"
                          onClick={() => this.handleQuantityChange(index, 1)}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td>${item.total.toFixed(2)}</td>
                    <td>
                      <button
                        className="wishlist-btn-add"
                        onClick={() => this.handleAddToCart(index)}
                      >
                        <i className="fas fa-cart-plus"></i>
                      </button>
                      <button
                        className="wishlist-btn-delete"
                        onClick={() => this.handleDelete(index)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

export default Wishlist;
